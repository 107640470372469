import { showPanel } from '@codemirror/view';
function headerPanel(options) {
  if (options === void 0) {
    options = {};
  }
  return view => {
    var dom = document.createElement('div');
    if (options.style !== 'none') {
      var _options$style;
      var actions = document.createElement('div');
      actions.className = 'cm-panel-actions-' + ((_options$style = options.style) == null ? void 0 : _options$style.toLocaleLowerCase());
      var exit = document.createElement('div');
      exit.className = 'exit';
      var minimize = document.createElement('div');
      minimize.className = 'minimize';
      var maximize = document.createElement('div');
      maximize.className = 'maximize';
      actions.appendChild(exit);
      actions.appendChild(minimize);
      actions.appendChild(maximize);
      dom.appendChild(actions);
      if (options.disableTitle) {
        var input = document.createElement('input');
        input.className = 'cm-panel-title-input';
        input.value = options.title || '';
        dom.appendChild(input);
      }
    }
    return {
      top: true,
      dom
    };
  };
}
export function showHeader(options) {
  return showPanel.of(headerPanel(options));
}